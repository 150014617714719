import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styles from './footer.module.scss';
import { Link } from 'react-scroll';

const Footer: FC = () => {
  return (
    <footer className={styles.footer}>
      <div>
        <h1 className={styles.header}>SOCIAL NETWORK</h1>
        <div className={styles['social-networks']}>
          <a
            href="https://www.facebook.com/jcerejo2"
            target="_blank"
            rel="noreferrer"
            aria-label="Link to my facebook profile"
          >
            <FontAwesomeIcon icon={icon({ name: 'facebook', style: 'brands' })} color="#939393" size="3x" />
          </a>
          <a
            href="https://www.linkedin.com/in/cerejo/"
            target="_blank"
            rel="noreferrer"
            aria-label="Link to my linkedin profile"
          >
            <FontAwesomeIcon icon={icon({ name: 'linkedin', style: 'brands' })} color="#939393" size="3x" />
          </a>
        </div>
      </div>
      <div className={styles['gradient-white']}>
        <Link className="footer-top" to="home" spy={true} smooth={true} offset={0} duration={500}></Link>
      </div>
    </footer>
  );
};

export default Footer;
