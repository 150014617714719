import React, { FC, useRef, useCallback, useState } from 'react';
import styles from './Contact.module.scss';
import emailjs from '@emailjs/browser';
import { useForm, SubmitHandler } from 'react-hook-form';

type FormValues = {
  user_name: string;
  user_email: string;
  message: string;
};

type ContactMessage = {
  type: string;
  message: string;
};

const Contact: FC = () => {
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      user_email: '',
      user_name: '',
      message: '',
    },
  });

  const [contactMessage, setContactMessage] = useState<ContactMessage>();
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues, event?: React.BaseSyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }

    setIsWaiting(true);
    sendEmail();
  };

  const sendEmail = () => {
    emailjs.sendForm('service_iwgtmfn', 'template_95sy3yl', form.current, '5oug5V7I2439DdiEd').then(
      () => {
        setIsWaiting(false);
        setContactMessage({
          type: 'success',
          message: 'The message was successfully sent!',
        });
        reset();
      },
      () => {
        setIsWaiting(false);
        setContactMessage({
          type: 'error',
          message: 'the message was not sent successfully!',
        });
      },
    );
  };

  const fieldIsNotEmpty = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTimeout(() => {
      if (e.target.value !== '') {
        e.target.classList.add('notEmpty');
      } else {
        e.target.classList.remove('notEmpty');
      }
    }, 0);
  }, []);

  return (
    <section id="contact">
      <div className={styles.container}>
        <div className={styles.row}>
          <h1 className={styles['header']}>Contact Me</h1>
          <div className={styles['contact-form']}>
            {contactMessage && (
              <p className={contactMessage.type === 'error' ? styles['error-message'] : styles['success-message']}>
                {' '}
                {contactMessage.message}
              </p>
            )}
            <form ref={form} onSubmit={handleSubmit(onSubmit)} className={styles.form} autoComplete="off" noValidate>
              <div className={styles['form-field']}>
                <input
                  id="user_name"
                  {...register('user_name', {
                    required: 'This field is required',
                    minLength: {
                      value: 3,
                      message: 'The field must be 3 or more characters',
                    },
                    maxLength: {
                      value: 25,
                      message: 'The field has a max number of 25 characters',
                    },
                    onChange: event => {
                      fieldIsNotEmpty(event);
                    },
                  })}
                  className={`${styles['input-text']} ${
                    errors.user_name ? styles['error-input'] : styles['without-error-input']
                  }`}
                />
                <label className={styles.label} htmlFor="user_name">
                  Name
                </label>
                <p className={styles['error-form-message']}>{errors.user_name?.message}</p>
              </div>
              <div className={styles['form-field']}>
                <input
                  id="user_email"
                  {...register('user_email', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                    onChange: event => {
                      fieldIsNotEmpty(event);
                    },
                  })}
                  className={`${styles['input-text']} ${
                    errors.user_email ? styles['error-input'] : styles['without-error-input']
                  }`}
                />
                <label className={styles.label} htmlFor="user_email">
                  E-mail
                </label>
                <p className={styles['error-form-message']}>{errors.user_email?.message}</p>
              </div>
              <div className={styles['form-field']}>
                <input
                  id="message"
                  {...register('message', {
                    required: 'This field is required',
                    onChange: event => {
                      fieldIsNotEmpty(event);
                    },
                  })}
                  className={`${styles['input-text']} ${
                    errors.message ? styles['error-input'] : styles['without-error-input']
                  }`}
                />
                <label className={styles.label} htmlFor="message">
                  Message
                </label>
                <p className={styles['error-form-message']}>{errors.message?.message}</p>
              </div>
              <div className={styles['form-field']}>
                <button className={styles['submit-btn']} type="submit" value="Submit">
                  {isWaiting ? <div className={`${styles.spinner} ${styles.spinner3}`}></div> : <span>submit</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
