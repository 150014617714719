import { FC, AnchorHTMLAttributes } from 'react';
import styles from './CustomButton.module.scss';

interface CustomButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  title: string;
}

const CustomButton: FC<CustomButtonProps> = ({ title, ...props }) => {
  return (
    <a rel="noreferrer" className={styles.btn} {...props}>
      {title}
    </a>
  );
};

export default CustomButton;
