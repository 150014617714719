import { FC } from 'react';
import styles from './About.module.scss';
import CustomButton from '../customButton';

const About: FC = () => {
  return (
    <section id="about" className={styles.container}>
      <div className={styles.row}>
        <h1 className={styles.header}>ABOUT</h1>
        <div className={styles['about-me']}>
          <p>
            I am Jose Cerejo, a Frontend Developer with over seven years of experience in creating engaging and
            user-friendly web applications. My passion is to craft intuitive interfaces that deliver value and
            satisfaction to the end-users, using cutting-edge technologies and best practices.
          </p>
          <br />
          <p>
            Currently, I work at Talkdesk, a leading cloud-based contact center platform that empowers businesses to
            provide exceptional customer service. As the lead Frontend Developer on my team, I spearhead the
            implementation of robust and efficient features, such as real-time data visualization, reusable components,
            and responsive design. I also contribute to the company's design system, using Storybook and React to create
            consistent and reusable UI elements. I enjoy collaborating with other developers and designers, and I am
            always open to feedback and improvement. My goal is to make a positive impact on the world through web
            development.
          </p>
          <div className={styles.buttons}>
            <CustomButton title="My Linkedin" href="https://www.linkedin.com/in/cerejo/" target="_blank" />
            <CustomButton title="Download my cv" href="/files/CV_JoseCerejo.pdf" download />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
