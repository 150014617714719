import React, { FC } from 'react';
import styles from './Experience.module.scss';

const Experience: FC = () => {
  return (
    <section id="experience">
      <div className={styles.container}>
        <div className={styles.row}>
          <h1 className={styles.header}>EXPERIENCE</h1>
          <ul className={`${styles.timeline} ${styles['timeline-split']}`}>
            <li className={styles['timeline-item']}>
              <p className={styles['timeline-info']}>October, 2021</p>
              <div className={styles['timeline-marker']}></div>
              <div className={styles['timeline-content']}>
                <h2 className={styles['timeline-title']}>Talkdesk</h2>
                <h3 className={styles['timeline-subTitle']}>Frontend Developer</h3>
                <p>
                  As the lead Frontend Developer on my team, I spearheaded implementation decisions to develop astrong
                  and robust product. My central role was pivotal in creating an efficient experience for the
                  client,ensuring that agents could access real-time information about each customer during calls. I
                  activelycontributed to building an intuitive and responsive interface, providing an interesting user
                  experience.
                </p>
                <p>
                  In the Talkdesk we are ensuring the precise implementation of design for our client. I also
                  volunteeredto create components in the company's design system for other teams.
                </p>
                <p>Responsibilities: </p>
                <ul className={styles.responsibilities}>
                  <li>User Interface Development</li>
                  <li>Development of Reusable Components</li>
                  <li>Integration with APIs</li>
                  <li>Performance Optimization</li>
                  <li>CI/CD</li>
                </ul>

                <p>
                  <b>Skills: </b>Storybook · redux toolkit · React · MongoDB · redux · Open API · Continuous Integration
                  and Continuous Delivery (CI/CD) · Lerna · Jest · PostgreSQL · Responsive Web Design · Version Control
                  · TypeScript
                </p>
              </div>
            </li>
            <li className={styles['timeline-item']}>
              <p className={styles['timeline-info']}>December, 2018</p>
              <div className={styles['timeline-marker']}></div>
              <div className={styles['timeline-content']}>
                <h2 className={styles['timeline-title']}>Critical TechWorks</h2>
                <h3 className={styles['timeline-subTitle']}>Frontend Developer</h3>
                <p>
                  In the Critical Techworks, I was part of a business unit focused on data management. Our primary
                  responsibility was to gather information from diverse departments and present it comprehensively in a
                  catalogue.
                </p>
                <p>
                  Through our application, individuals in each department could exclusively access information relevant
                  to their specific department, ensuring restricted access solely to pertinent data with several
                  metrics.
                </p>
                <p>Responsibilities: </p>
                <ul className={styles.responsibilities}>
                  <li>Work very closely with the designer </li>
                  <li>Cross-browser Compatibility "Internet Explorer"</li>
                  <li>Create multiple metrics in D3.js</li>
                  <li>Responsive website and creation of our own components</li>
                </ul>

                <p>
                  <b>Skills: </b> Angular · Jest · Agile Methodologies · Cypress · SCSS · PostgreSQL · Java · Responsive
                  Web Design · Version Control · D3.js · Cross-browser Compatibility · TypeScript
                </p>
              </div>
            </li>
            <li className={styles['timeline-item']}>
              <p className={styles['timeline-info']}>August, 2017</p>
              <div className={styles['timeline-marker']}></div>
              <div className={styles['timeline-content']}>
                <h2 className={styles['timeline-title']}>China Systems</h2>
                <h3 className={styles['timeline-subTitle']}>Frontend Developer</h3>
                <p>
                  At China Systems we were developing one client-side home banking and another one for respective
                  banking. My first contact with the waterfall methodology.
                </p>
                <p>Responsibilities:</p>
                <ul className={styles.responsibilities}>
                  <li>Cross Browsing in Internet Explorer 9+</li>
                  <li>Responsive Website</li>
                  <li>Polyfill to providing compatibility with older browsers</li>
                  <li>Version Control</li>
                </ul>
                <p>
                  <b>Skills: </b> avaScript · Responsive Web Design · Version Control · IBM DB2 · jQuery · JSP · XML ·
                  Cascading Style Sheets (CSS) · HTML · Cross-browser Compatibility
                </p>
              </div>
            </li>
            <li className={styles['timeline-item']}>
              <p className={styles['timeline-info']}>September, 2016</p>
              <div className={styles['timeline-marker']}></div>
              <div className={styles['timeline-content']}>
                <h2 className={styles['timeline-title']}>Bee Engineering</h2>
                <h3 className={styles['timeline-subTitle']}>IT Consultant</h3>
                <p>
                  At Bee Engineering I had the opportunity to work and develop internally for three months a project
                  from scratch in "AngularJS" in preparation for the market. After those three months, I went as a
                  consultant to China Systems where I ended up becoming a permanent employee of the company after six
                  months as a consultant.
                </p>
                <p>
                  <b>Skills: </b>AngularJS · HTML5 · CSS · MySQL · JavaScript · Git
                </p>
              </div>
            </li>
            <li className={styles['timeline-item']}>
              <p className={styles['timeline-info']}>April, 2013/{new Date().getFullYear()}</p>
              <div className={styles['timeline-marker']}></div>
              <div className={styles['timeline-content']}>
                <h2 className={styles['timeline-title']}>Boato</h2>
                <h3 className={styles['timeline-subTitle']}>Freelancer</h3>
                <p>As a freelancer, I am currently the head of development in web design projects for Boato.</p>
                <p>
                  <b>Skills: </b>Web Hosting · SCSS · HTML5 · WordPress · JavaScript
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Experience;
