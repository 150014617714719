import { FC } from 'react';
import styles from '../Slide.module.scss';
import 'react-awesome-slider/dist/styles.css';

const Home: FC = () => {
  return (
    <section id="home">
      <div className={styles.container}>
        <picture>
          <img className={styles.image} src={'/images/me.webp'} alt="Slide simple images" />
        </picture>
        <div className={styles['inner-container']}>
          <h1 className={styles.title}>Hi, I'm José Cerejo</h1>
          <div className={styles.animation}>
            <p className={styles['skill']}>Frontend Developer</p>
            <p className={styles['skill']}>React</p>
            <p className={styles['skill']}>Performance Optimization</p>
            <p className={styles['skill']}>CI/CD</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
