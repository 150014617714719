import React, { FC } from 'react';
import styles from './skills.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const Skills: FC = () => {
  return (
    <section id="skills" className={styles.skills}>
      <div className={styles.row}>
        <FontAwesomeIcon icon={icon({ name: 'js', style: 'brands' })} size="6x" color="#939393" />
        <FontAwesomeIcon icon={icon({ name: 'html5', style: 'brands' })} size="6x" color="#939393" />
        <FontAwesomeIcon icon={icon({ name: 'react', style: 'brands' })} size="6x" color="#939393" />
        <FontAwesomeIcon icon={icon({ name: 'angular', style: 'brands' })} size="6x" color="#939393" />
        <FontAwesomeIcon icon={icon({ name: 'sass', style: 'brands' })} size="6x" color="#939393" />
        <FontAwesomeIcon icon={icon({ name: 'jenkins', style: 'brands' })} size="6x" color="#939393" />
        <FontAwesomeIcon icon={icon({ name: 'git', style: 'brands' })} size="6x" color="#939393" />
        <FontAwesomeIcon icon={icon({ name: 'wordpress', style: 'brands' })} size="6x" color="#939393" />
      </div>
    </section>
  );
};

export default Skills;
