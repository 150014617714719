import { FC, lazy, Suspense } from 'react';
import SandwichNavBar from './components/navBar/sandwich/Sandwich';
import './assets/styles/style.scss';
import Footer from './components/footer';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './components/slide/simple';
import About from './components/about';
import Experience from './components/experience';
import Contact from './components/contact';
import Skills from './components/skills';
import Test from './features/Test';

const NotFound = lazy(() => import('./components/notFound'));

const App: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <main className="container">
                  <Home />
                  <About />
                  <Experience />
                  <Contact />
                  <Skills />
                </main>
                <Footer />
              </>
            }
          />
          <Route path="/test" element={<Test />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>

      <SandwichNavBar />
    </BrowserRouter>
  );
};

export default App;
