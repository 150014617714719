import { useState, FC, useCallback } from 'react';
import styles from '../NavBar.module.scss';
import { Link as LinkRoll } from 'react-scroll';
import { useNavigate, useLocation } from 'react-router-dom';

const SandwichNavBar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const navigateToHome = useCallback(() => {
    const path = location.pathname;

    if (path !== '/') {
      navigate('/');
    }

    setMenuOpen(!menuOpen);
  }, [location.pathname, menuOpen, navigate]);

  const handleCheckboxChange = () => setMenuOpen(!menuOpen);

  return (
    <div className={styles['sandwich-navigation']}>
      <label>
        <input
          className={styles['input-checkbox']}
          type="checkbox"
          checked={menuOpen}
          onChange={handleCheckboxChange}
        />
        <span className={styles.menu}>
          <i className={styles.hamburger}></i>
        </span>
        <nav>
          <li>
            <LinkRoll
              activeClass="menu-activated"
              to="home"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={navigateToHome}
            >
              Home
            </LinkRoll>
          </li>
          <li>
            <LinkRoll
              activeClass="menu-activated"
              to="about"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              onClick={navigateToHome}
            >
              About
            </LinkRoll>
          </li>
          <li>
            <LinkRoll
              activeClass="menu-activated"
              to="experience"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              onClick={navigateToHome}
            >
              Experience
            </LinkRoll>
          </li>
          <li>
            <LinkRoll
              activeClass="menu-activated"
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={navigateToHome}
            >
              Contact
            </LinkRoll>
          </li>
          <li>
            <LinkRoll
              activeClass="menu-activated"
              to="skills"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={navigateToHome}
            >
              Skills
            </LinkRoll>
          </li>
        </nav>
      </label>
    </div>
  );
};

export default SandwichNavBar;
